/* design1 */
.design1details {


  .video-background-holder {
    position: relative;
    background-color: black;
    /* height: calc(70vh - 72px);
    min-height: 25rem; */
    width: 100%;
    overflow: hidden;
  }

  .video-background-holder video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .video-background-content {
    position: relative;
    z-index: 2;
  }

  .video-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.295);
    opacity: 0.5;
    z-index: 1;
  }


  /* LEFT RIGHT CARDS */
  .parallal-text-card-nys {
    background-color: #f5f5f5;
    border-radius: 50px;


    .parallal-text-card-heading-nys h2 {
      font-weight: bolder;
      color: #1b676352;
    }

    .parallal-text-card-heading-nys span {
      color: #1b6763;
      text-transform: uppercase;
    }

    .text-left-nys h6 {
      color: #8c8c8c;
      text-align: justify;
      margin-top: 2%;

    }

    .img-right-nys {
      margin-top: -2%;
    }
  }

  @media (max-width: 600px) {
    .parallal-text-card-nys {
      padding: 10% 0;
    }

    .parallal-text-card-heading-nys h2 {
      font-size: 1.8rem;
    }

    .text-left-nys h6 {
      font-size: 1rem;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .img-right-nys {
      margin-top: 0;
    }
  }

  @media (min-width: 601px) {
    .parallal-text-card-nys {
      padding: 4% 0;
    }

    .parallal-text-card-heading-nys h2 {
      font-size: 2.75rem;
    }

    .text-left-nys h6 {
      font-size: 1.1rem;
      line-height: 25px;
      margin-bottom: 25px;
    }

    .img-right-nys {
      margin-top: 0;
    }
  }



  .adatable-head {
    margin: 40px 0px;

    h5 {
      text-align: center;
      font-weight: bolder;
      font-size: 2rem;
    }
  }

  .vhcf-section,
  .vhcf-section .vhcf-row {
    width: 100%;
    display: block;
  }

  .vhcf-section .vhcf-row .vhcf-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: center; */
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
  }

  .vhcf-section .vhcf-row .vhcf-column .vhcf-module {
    width: 50%;
    box-sizing: border-box;
    text-align: justify;
  }

  .vhcf-section .vhcf-row .vhcf-column .vhcf-module img {
    width: 100%;
    height: 70vh;
    border: 1px solid #ddd;
  }

  /* // */
  .u-mb-96 {
    @media screen and (max-width: 991px) {
      .h0 {
        font-size: 4rem;
      }
    }

    .h0 {
      letter-spacing: .01em;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.04;
    }

    @media screen and (max-width: 991px) {
      h2 {
        font-size: 3rem;
      }
    }

    h2 {
      letter-spacing: .01em;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 1.04;
    }

    h2 {
      margin-top: 20px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  .four-cards-parallal-section-cards-nys {
    .four-cards-parallal-main-cards {}

    .four-cards-parallal-main-cards .card {
      border: none;
      outline: none;
      background-color: #f5f5f5;
      position: relative;
      overflow: hidden;
    }

    .four-cards-parallal-main-cards .card-body {}

    .four-cards-parallal-main-cards .card-title {
      font-size: 1.75rem;
      color: #333333;
      margin: 25px 0 10px 0;
      text-transform: uppercase;
    }

    .four-cards-parallal-main-cards .card-text {
      font-size: 1rem;
      color: #8c8c8c;
      margin-bottom: 10px;
    }

    .four-cards-parallal-main-cards .Parallal-img-nys {}

    .image-container {
      overflow: hidden;
    }

    .Parallal-img-nys {
      transition: transform 0.3s ease;
      transition: 1s;
    }

    .card:hover .Parallal-img-nys {
      transform: scale(1.1);
      transition: 1s;
    }


  }

  .key-features-products-section-nys {
    .key-features-products-nys {
      margin: 75px 0;
    }

    .key-features-products-nys .card {
      outline: none;
      border: none;
      background-color: transparent;
      padding: 30px 0;
      border-radius: 30px 0 30px 0;
      box-shadow: 30px 30px 40px 0px #1b67633b;
      transition: 1s;
    }

    .key-features-products-nys .card:hover {
      transform: scale(1.2);
      transition: 1s;
      color: #1b6763;
    }

    .key-features-products-nys .card-body {
      background-color: transparent;
    }

    .key-features-products-nys .card-title {
      text-align: center;
    }
  }

}