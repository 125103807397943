.image-container22 {
  /* width: 50vw; */
  height: 300px;
  position: relative;
  overflow: hidden;
  display: flex;

  /* margin: 50px; */
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:nth-child(odd) {
    margin-left: random(400) - 100 + px;
  }
}

.test01 {
  .box01 {
    position: relative;
    height: 75vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-container22 {
    width: 50vw;
    height: 300px;
    position: relative;
    overflow: hidden;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* ///////////////////// */
.flex-container-nystai-scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 2px solid #0000001f;
}

.flex-container-nystai-scroll .slider-container {
  height: 90px;
  overflow: hidden;
  position: relative;
  width: 90%;
}

.flex-container-nystai-scroll .slider-container .slider {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.flex-container-nystai-scroll .slider-container .slider__slide {
  height: 90px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-container-nystai-scroll .slider-container .slider__slide:hover {
  cursor: pointer;
}

.flex-container-nystai-scroll .slider-container .slider__slide .slide__content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(53, 51, 51);
}


.slick-card-nys {
  border: none;
  outline: none;
  margin: 20px 20px 20px 0px;
  background-color: transparent;
}

.slick-card-img-nys {
  border-radius: 10px;
  transition: all 1S;
  height: 25vh;
  width: 100%;
}

.slick-card-img-nys:hover {
  transform: scale(1.05);
}

.slick-card-body-nys p {
  color: white;
}

.main-slider-nys .sider {
  margin-left: 12%;
}

#orange-sectionmain3 {
  background-image: url("../IMAGES-VIDEOS//A-IMG-HOME-LAYOUT/common/Parallax-3.png");
  background-attachment: fixed;
  background-size: cover;
  box-sizing: border-box;
  text-align: center;
  padding: 5% 0;
}

#orange-sectionmain3 h1 {
  background-color: #ffffff28;
  font-weight: bolder;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#orange-sectionmain3 p {
  color: #000000;
  padding: 1% 0 2% 0;
  line-height: 30px;
  font-size: 23px;
}

.logoscroll {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  text-align: center;
  padding: 40px 0 10px 0;
}

.headlogoscroll {
  color: #1b6763;
}

.scroll-logo-home-nystai {
  height: 60px;
  margin: 8px;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

.Marquee {
  box-sizing: border-box;
  padding: 0.5em;
  color: white;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.Marquee-content {
  display: flex;
  animation: marquee 50s linear infinite running;
}

.Marquee-content2 {
  display: flex;
  animation: marquee2 70s linear infinite running;
}

.Marquee-tag {
  width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0s ease;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(-50%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 1920px) {
  .hover-tab-body-nystai {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1919px) {
  .hover-tab-body-nystai {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1680px) {
  .hover-tab-body-nystai {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1366px) {
  .hover-tab-body-nystai {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .hover-tab-body-nystai {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  .common-top-div {
    padding: 40px 0px 40px 0px;
    text-align: center;
  }

  .head-h-divtopic {
    font-size: 3em;
    font-weight: bolder;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    letter-spacing: 3px;
    font-size: 1.5em;
    font-weight: bolder;
  }

  .head-h-divtopic-bgwhite {
    color: #ffffff;
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  #orange-sectionmain3 h1 {
    padding: 3% 0 3% 0;
    font-weight: bolder;
    text-transform: uppercase;
  }

  #orange-sectionmain3 p {
    padding: 1% 0 2% 0;
    line-height: 30px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1280px) {
  .flex-container-nystai-scroll .slider {
    --slider-inner-width: 1000px;
    --slider-speed: 10s;
  }

  @keyframes scroll {
    0% {
      transform: translateX(-5%);
    }

    100% {
      transform: translateX(calc((0px - var(--slider-inner-width)) - 5%));
    }
  }

  .flex-container-nystai-scroll .slider {
    animation: scroll linear infinite var(--slider-speed);
    width: calc(2 * var(--slider-inner-width));
    transition: animation-play-state ease 2s;
  }

  .flex-container-nystai-scroll .slider:hover {
    animation-play-state: paused;
  }

  .flex-container-nystai-scroll .slider-container {
    height: 90px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  #orange-sectionmain3 h1 {
    padding: 3% 0 3% 0;
    font-weight: bolder;
    text-transform: uppercase;
  }

  #orange-sectionmain3 p {
    padding: 1% 0 2% 0;
    line-height: 30px;
    font-size: 18px;
  }

  /* commontopdivtext */
  .common-top-div {
    padding: 50px 0px 50px 0px;
    text-align: center;
  }

  .head-h-divtopic {
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    font-size: 1em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .head-h-divtopic-bgwhite {
    color: #ffffff;
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 950px) {

  .section-details,
  .swiper-button-testmonials-prev {
    display: none;
  }

  .common-top-div {
    padding: 50px 0px 50px 0px;
    text-align: center;
  }

  .head-h-divtopic {
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    font-size: 1em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .head-h-divtopic-bgwhite {
    color: #ffffff;
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 600px) {
  .common-top-div {
    padding: 50px 0px 50px 0px;
    text-align: center;
  }

  .head-h-divtopic {
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    font-size: 1em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .head-h-divtopic-bgwhite {
    color: #ffffff;
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 340px) {
  .common-top-div {
    /* padding: 50px 0px 50px 0px; */
    text-align: center;
  }

  .head-h-divtopic {
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    font-size: 1em;
    letter-spacing: 0.25em;
    line-height: 1.1;

  }

  .head-h-divtopic-bgwhite {
    color: #ffffff;
    font-size: 2em;
    letter-spacing: 0.25em;
    line-height: 1.1;
  }
}

/* TESI */
.section-testmonials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.column-testmonials {
  border-right: 2px solid #1b6763;
  display: flex;
  flex-direction: column;
}

.section-details {
  flex-direction: column;
  border-left: 10px solid #222;
  padding-left: 20px;
}

.name-section {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 22px;
  color: #1b6763 !important;
  line-height: 1.2em;
  margin: 0;
}

.title-section b {
  color: #1b6763;
}

.title-section {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.navigation-testmonials {
  display: flex;
}

.swiper-testmonials {
  width: 90%;
  overflow: hidden;
  position: relative;
}

.swiper-testmonials .swiper-slide {
  border-radius: 10px;
  background: linear-gradient(118deg, #1b6763da 10.88%, #1b6763 83.88%);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.2s;
  height: 180px;
}

.card-slide {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.head-slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-slide {
  display: flex;
  align-items: center;
  gap: 20px
}

.header-slide-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.header-slide-circle img {
  height: 20px;
  width: fit-content;
}

.title-slide h4 {
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
}

.title-slide h6 {
  color: #FFCB00;
  font-size: 14px;
  font-weight: 500;
}

.text-slide {
  color: #FFF;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

/* right card left card */
.rlcard-textimg-services-ist {
  border: none;
}

/* wlc */
.footerBoxes .col {
  padding: 0px 7px;
}

.footerBoxes .col:first-child,
.footerBoxes .col:last-child {
  padding: 0px;
}

.footerBoxes .box {
  background: #c4c4c4;
  padding: 25px 20px;
  border-radius: 15px;
  height: 15vh;
}

.footerBoxes .box .info {
  padding-left: 15px;
}

.footerBoxes .box img {
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 40px;
}

.footerBoxes .box .info h4 {
  font-size: 15px;
  font-weight: 600;
}

.footerBoxes .box .info p {
  margin-bottom: 0px;
  font-size: 15px;
  opacity: 0.8;
}

.footerBoxes .box:hover img {
  transform: translateY(-5px);
}
