.suppport-nystai-ssp {
  color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
 
a {
  color: #000000;
}
 
/* ////////// */
.variant {
  flex: 5;
  text-align: center;
}
 
.contact-form {
  margin: 25px auto;
  padding: 13px;
  border-radius: 10px;
 
}
 
.variant--separator {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
 
.variant--separator span {
  padding: 10px;
  font-weight: 600;
}
 
.variant:after,
.variant:before {
  content: '';
  width: 1px;
  flex: 1;
  background-color: purple;
}
 
@media (max-width: 375px) {
  .choose-ur-destiny .container {
    flex-direction: column;
  }
 
  .variant--separator {
    flex-direction: row;
  }
 
  .variant--separator:after,
  .variant--separator:before {
    height: 1px;
    width: auto;
    flex-direction: row;
  }
}
 
.suppport-nystai-ssp .suppport-nystai-ssp-h1 {
  font-weight: bolder;
  color: #f2f2f2;
}
 
.suppport-nystai-ssp-h1 {
  padding-top: 60px;
  padding-bottom: 30px;
}
 
.phone-mobilenystai-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
 
.phone-mobilenystai-sec .card {
  color: #f2f2f2;
  border: none;
  background-color: transparent;
}
 
.phone-mobilenystai-sec div {
  padding: 30px;
}
 
.phone-mobilenystai-sec .h1-phone-mobile-nystai {
  text-transform: uppercase;
}
 
.suppport-nystai-ssp-card {
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
 
}
 
.suppport-nystai-ssp-card .card {
  border: none;
  background-color: transparent;
  color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 10px 10px;
 
}
 
.suppport-nystai-ssp-card img {
  margin-bottom: 10px;
  height: 7vh;
  width: fit-content;
}
 
.suppport-nystai-ssp-card p {
  border: 1px solid #f2f2f233;
  margin-top: 15px;
  padding: 8px 13px 8px 13px;
  border-radius: 7px;
  letter-spacing: 1px;
}
 
.suppport-nystai-ssp-card p .fontsni {
  color: #f2f2f2a1;
}
 
.suppport-nystai-ssp-card p:hover {
  background-color: #1b6763;
  color: #000;
 
}
 
 
/* ////////////////// */
@media only screen and (min-width: 1280px) {
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(1) {
    border-right: 1px solid #353535;
  }
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(2) {
    border-right: 1px solid #353535;
  }
}
 
@media only screen and (max-width: 1280px) {
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(1) {
    border-right: 1px solid #353535;
  }
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(2) {
    border-right: 1px solid #353535;
  }
}
 
@media only screen and (max-width: 950px) {
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(1) {
    border-right: 1px solid #353535;
  }
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(2) {
    border-right: 1px solid #353535;
  }
 
  .suppport-nystai-ssp-card .card {
    width: 250px;
    padding: 10px 10px;
 
  }
 
  .suppport-nystai-ssp-card p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
 
  }
 
}
 
@media only screen and (max-width: 768px) {
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(1) {
    border-right: 1px solid #353535;
  }
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(2) {
    border-right: 1px solid #353535;
  }
 
  .phone-mobilenystai-sec {
    padding-bottom: 5px;
  }
 
  .phone-mobilenystai-sec .card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .phone-mobilenystai-sec div {
    padding: 8px;
  }
 
  .whats-icon-support-nystai {
    height: 7vh;
    width: fit-content;
  }
}
 
@media only screen and (max-width: 540px) {
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(1) {
    border-right: 0px;
  }
 
  .suppport-nystai-ssp-card .card-nystai-support-nys:nth-child(2) {
    border-right: 0px;
  }
 
 
 
  .phone-mobilenystai-sec .card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
 
 
 
/* form */
input[ type=text],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  resize: vertical;
}
 
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
input[type=submit]:hover {
  background-color: #45a049;
}
 
.sales-form-support-nystai {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
 
 
/* faqs */
.faq-supp-nystai-acc {
  color: #000;
  padding: 50px 0;
  background-color: #f5f5f5;
}
 
.accordion {
  color: #000;
  width: 100%;
  max-width: 800px;
  margin: 30px auto -5px;
  background: #fbfbfb !important;
}
 
.accordion-button {
  color: #000;
  font-size: medium;
  letter-spacing: 1px;
  background-color: #fbfbfb !important;
}
 
.accordion-button:not(.collapsed) {
  color: #1b6763;
  background-color: #f5f5f5 !important;
  text-decoration: underline;
 
}
 
.accordion-collapse {
  color: #1b6763;
  font-size: small;
  letter-spacing: 2px;
  line-height: 20px;
}
 
.accordion-collapse code {
  color: #1b6763;
  font-weight: bold;
}
 
 
 
 
/* new form */
.ban-img {
  background-image: url('../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT//contatus.png');
  background-size: cover;
  background-position: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #000000;
  padding: 5% 0;
}
 
.ban-img h1 {
  font-size: 4rem;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
 
.img-form {
  justify-content: center;
  flex: wrap;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(95, 148, 145, 0.527) 50%, rgba(255, 255, 255, 1) 90%);
}
 
.img-sec {
  border-radius: 10px;
  margin: 90px auto;
  justify-content: center;
  align-items: center;
}
 
.img-area {
  justify-content: center;
  flex-wrap: wrap;
}
 
 
 
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 13px;
}
 
.form-group {
  position: relative;
  /* width: 48%; */
}
 
 
 
 
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 5px 12px 12px 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
 
.react-select__control {
  height: 50px;
}
 
.form-group textarea {
  height: 100px;
}
 
.phone-input-group {
  height: 50%;
}
 
.phone-input-group input[type="tel"] {
  height: fit-content;
  width: 100%;
}
 
.form-group input::placeholder {
  font-size: 14px;
  color: #0000006e;
}
 
.form-icon {
  position: absolute;
  top: 35%;
  left: 25px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #888;
}
 
.form-icon1 {
  position: absolute;
  top: 20%;
  left: 23px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #888;
}
 
.privacy-policy {
  display: flex;
}
 
.privacy-policy input {
  margin: 0px 15px 35px 10px;
}
 
.button-reset {
  padding: 10px 15px;
  border: none;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  margin: 2%;
 
}
 
.button-submit {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1b6763;
  color: #ffffff;
  cursor: pointer;
  margin: 2%;
}
 
/* .last-buttons{
  justify-content: flex-end;
} */
 
.captcha-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
 
.recaptcha-error {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
 
 
 
.contact-right-support-nystai {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  text-align: center;
  flex-direction: column;
}
 
.list-group-item {
  border: none;
  background: #F6F8FA;
}
 
.support-sales-card-nystai {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background: #F6F8FA;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #9898988c, -10px -10px 20px #ffffff;
  transition: 1s;
}
 
.support-sales-card-nystai li {
  font-weight: 500;
  font-size: 15px;
  font-size: small;
}
 
.support-sales-card-nystai img {
  height: 12vh;
  width: fit-content;
}
 
.support-sales-card-nystai .list-group-item {
  letter-spacing: 0.1rem;
  line-height: 0.25rem;
}
 
 
.support-sales-card-nystai:hover {
  border-radius: 19px;
  background: #F6F8FA;
  box-shadow: -5px -5px 10px #989898a6,
    10px 10px 20px #ffffff;
  transition: 1s;
  transform: scale(1.01);
}
 
 
 
 
 
 
 
/* //// */
.darksoul-card1 {
  position: relative;
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 10px;
}
 
/* .darksoul-card1:hover {
  transform: scale(1.1);
} */
 
.tagline {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #f5f5f5;
  font-size: 14px;
}
 
.darksoul-card1:hover .tagline {
  display: block;
}
 
.card-form-click {
  width: 100%;
  height: fit-content;
  /* margin: 20px 0px 0px 0px; */
  display: flex;
 
  a {
    margin: auto;
    text-decoration: none;
  }
 
  .darksoul-card1 {
    display: flex;
    margin: auto;
    /* width: 200px; */
    height: 120px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    transition: all 2s;
    align-items: start;
    justify-content: right;
    border: 1px solid #0000004d;
  }
 
  .circle1 {
    /* position: absolute;
    margin: auto;
    margin-right: 0px;
    width: 50px;
    height: 50px;
    background: radial-gradient(circle at 30% 107%, #1b6763 0%, #727171 100%);
    border-radius: 0px 15px 0px 50px;
    transition: all 0.5s; */
 
  }
 
  /* .darksoul-card1:hover .circle1 {
    background: radial-gradient(circle at 30% 107%, #1b6763 0%, #636262 100%);
    width: 200px;
    height: 120px;
    border-radius: 15px;
    transition: all 0.5s;
  }
 
 
  .darksoul-card1:hover .content {
    color: white;
  } */
 
  .content {
    margin: auto;
    z-index: 100;
    color: #000;
    font-weight: 500;
    font-size: 23px;
  }
 
 
 
  .darksoul-card1 .card-click-icon {
    position: absolute;
    margin: auto;
    padding-top: 10px;
    padding-right: 15px;
    z-index: 100;
  }
 
 
  .disclaimer {
    position: absolute;
    bottom: 0px;
    left: 0;
    margin-left: auto;
    right: 0;
    margin-right: auto;
    width: fit-content;
    color: rgb(0, 0, 0);
    text-align: center;
  }
 
  .disclaimer a {
    text-decoration: none;
    color: #202020;
    font-weight: 900;
  }
 
  /*
  .disclaimer a:hover {
    text-decoration: overline;
  } */
}
 
 
@media (max-width: 600px) {
  .contact-right-support-nystai h1{
    font-size: 30px;
    font-weight: bold;
  }
  .parallel-opp-img{
    border:none;
  }
}
 
.parallel-opp-img{
  border-right: 1px solid #1b67632f;
}

