
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #f5f5f5; 
  }
  .common-top-div {
    padding: 40px 0px 40px 0px;

    text-align: center;
  }

  .head-h-divtopic {
    font-size: 3em;
    font-weight: bolder;
    line-height: 1.1;
  }

  .sechead-h-divtop {
    letter-spacing: 3px;
    font-size:1.5em;
    font-weight: bolder;


  }