.Blogs-body-nys {
    background-color: #f5f5f5;
}

/*Single big card */
.caro-cards-nys {

    .caro-cards-heading-nys {
        padding: 2.5% 0;

        h3 {
            font-weight: bold;
            font-size: 2rem;
        }

        p {
            color: #8c8c8c;
            margin-bottom: 0;
        }

    }

    .caro-main-cards-nys {
        padding: 0 0 2.5% 0;
    }

    .caro-main-cards-nys .card {
        border: none;
        outline: none;
        box-shadow: 5px 10px 30px 0px #1b67631c;
    }

    .caro-main-cards-nys .card:hover {
        outline: 1px solid #1b676341;
    }

    .card-right-contents-nys {
        padding: 5% 0 0 2.5%;
    }

    .card-right-contents-nys .card-title {
        font-size: 1.75rem;
    }

    .card-right-contents-nys .card-text {
        color: #8c8c8c;
    }

    .card-right-contents-nys .card-text2 {
        font-size: 1.2rem;
        color: #1b6763;
    }

    .caro-main-cards-nys .image-wrapper {
        overflow: hidden;
        /* Ensures the image doesn't overflow the container */
        position: relative;
    }

    .caro-main-cards-nys .card-image {
        transition: transform 0.4s ease-in-out;
        /* Smooth zoom effect */
    }

    .caro-main-cards-nys:hover .card-image {
        transform: scale(1.2);
        /* Zoom in the image */
    }


}

/* Carousel card */
.carousel-cards-nys {
    padding-bottom: 2.5%;
    cursor: pointer;

    .carousel-cards-main-nys {
        padding: 0 0 2.5% 0;

        h3 {
            font-weight: bold;
            font-size: 2rem;
        }

        p {
            color: #8c8c8c;
            margin-bottom: 0;
        }
    }

    .carousel-cards-contents-nys .card {
        border: none;
        outline: none;
        box-shadow: 5px 10px 30px 0px #1b67631c;
        position: relative;
    }

    .carousel-cards-contents-nys .card:hover {
        /* bottom: 10px; */
    }

    .left-right-arrow-nys {
        height: 50px;
        width: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1b67637c;
    }

    .left-right-arrow-nys:hover {
        background-color: #1b6763;
        color: white;
        cursor: pointer;
        transition: 1s;

    }
}

/* sticky cards */
.sticky-cards-nys {
    cursor: pointer;

    .sticky-cards-heading-nys {
        padding: 2.5% 0;

        h3 {
            font-weight: bold;
            font-size: 2rem;
        }

        p {
            color: #8c8c8c;
            margin-bottom: 0;
        }
    }

    /* left contents */
    .sticky-cards-left-contents-nys {}

    .sticky-cards-left-contents-nys .card {
        border: none;
        outline: none;
        box-shadow: 5px 10px 30px 0px #1b67631c;
    }

    .sticky-cards-left-contents-nys .card:hover {
        /* outline: 1px solid #1b676341; */
        bottom: 10px;
    }

    .sticky-cards-left-contents-nys .card-title {
        font-size: 1.75rem;
    }

    .sticky-cards-left-contents-nys p {}


    /* right contents */
    .sticky-cards-right-contents-nys {
        position: sticky;
        top: 5.5rem;
        border: none;
        outline: 1px solid #1b67631e;
        box-shadow: 5px 10px 30px 0px #1b67631c;
    }

}

