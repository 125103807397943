.privacypolicy-body-nystai {
  margin-left: 200px;
  margin-right: 200px;
  overflow-x: hidden;
}

@media only screen and (max-width: 1400px) {
  .privacypolicy-body-nystai {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 580px) {
  .privacypolicy-body-nystai {
    margin-left: 40px;
    margin-right: 40px;

  }
}

.underline-nytai {
  text-decoration: underline;
}

.text-bold {
  font-size: medium;
  font-weight: 500;
}

.warrenty-head3 {
  font-size: 25px;
  font-weight: 400;
}

.ti-cont img {
  margin: 5px;
  margin-right: 245px;
}

.ti-cont h1 {
  margin-top: -50px;
  margin-left: 100px;
  overflow-x: hidden;
}

@media only screen and (max-width: 375px) {
  .ti-cont h1 {
    margin-left: 50px;
    font-size: 25px;
    padding: 5px;
  }
}

.tit-logo {
  height: 50px;
  width: 50px;
  margin: 5px;
}

.titlefor-privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-shadow: 0px 13px 5px 0px rgba(0, 0, 0, 0.253);
  -moz-box-shadow: 0px 13px 5px 0px rgba(0, 0, 0, 0.137);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.308);

}

.pri-logo {
  width: fit-content;
  height: 130px;
  cursor: pointer;
}