.carousel-nystai-home1 {
    height: 70vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.carousel-nystai-home1 .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}

.img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-nystai-home1 .list .item .content {
    position: absolute;
    top: 62%;
    left: 44%;
    width: 1140px;
    max-width: 80%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
}

.carousel-nystai-home1 .list .item .author {
    font-weight: bold;
    letter-spacing: 10px;
}

.carousel-nystai-home1 .list .item .title,
.carousel-nystai-home1 .list .item .topic {
    font-size: 3em;
    font-weight: bold;
    line-height: 1.3em;
}

.carousel-nystai-home1 .list .item .topic {
    color: #1b6763;
}

.carousel-nystai-home1 .list .item .buttons {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
}

.carousel-nystai-home1 .list .item .buttons button {
    border: none;
    background-color: #eee;
    letter-spacing: 3px;
    font-weight: 500;
}

.carousel-nystai-home1 .list .item .buttons button:nth-child(2) {
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}


.thumbnail-nystai-slider-home1 {
    position: absolute;
    bottom: 50px;
    left: 80%;
    width: max-content;
    z-index: 1;
    display: flex;
    gap: 20px;
}

.thumbnail-nystai-slider-home1 .item {
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
}

.thumbnail-nystai-slider-home1 .item .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.thumbnail-nystai-slider-home1 .item .content {
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 10px;
}

.thumbnail-nystai-slider-home1 .item .content .title {
    font-weight: 300;
}

.content .des {
    font-size: 2vh;
    font-weight: 300;
}

.bar-item-text-line {
    border-radius: 10px;
    height: 5px;
    width: 40px;
    background-color: #1b6763;
    margin-bottom: 7px;
}


.arrows {
    position: absolute;
    top: 80%;
    right: 10%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.arrows button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eee4;
    border: none;
    color: #fff;
    font-weight: bold;
    transition: .5s;
}

.arrows button:hover {
    background-color: #1b6763;
    color: #000;
}


.carousel-nystai-home1 .list .item:nth-child(1) {
    z-index: 1;
}



.carousel-nystai-home1 .list .item:nth-child(1) .content .author,
.carousel-nystai-home1 .list .item:nth-child(1) .content .bar-item-text-line,
.carousel-nystai-home1 .list .item:nth-child(1) .content .title,
.carousel-nystai-home1 .list .item:nth-child(1) .content .topic,
.carousel-nystai-home1 .list .item:nth-child(1) .content .des,
.carousel-nystai-home1 .list .item:nth-child(1) .content .buttons {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}

@keyframes showContent {
    to {
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.carousel-nystai-home1 .list .item:nth-child(1) .content .bar-item-text-line {
    animation-delay: 1.1s !important;
}

.carousel-nystai-home1 .list .item:nth-child(1) .content .title {
    animation-delay: 1.2s !important;
}

.carousel-nystai-home1 .list .item:nth-child(1) .content .topic {
    animation-delay: 1.4s !important;
}

.carousel-nystai-home1 .list .item:nth-child(1) .content .des {

    animation-delay: 1.6s !important;
}

.carousel-nystai-home1 .list .item:nth-child(1) .content .buttons {
    animation-delay: 1.8s !important;
}


.carousel-nystai-home1.next .list .item:nth-child(1) .img {
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}

@keyframes showImage {
    to {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel-nystai-home1.next .thumbnail-nystai-slider-home1 .item:nth-last-child(1) {
    overflow: hidden;
    animation: showthumbnail-nystai-slider-home1 .5s linear 1 forwards;
}

.carousel-nystai-home1.prev .list .item .img {
    z-index: 100;
}

@keyframes showthumbnail-nystai-slider-home1 {
    from {
        width: 0;
        opacity: 0;
    }
}

.carousel-nystai-home1.next .thumbnail-nystai-slider-home1 {
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext {
    from {
        transform: translateX(150px);
    }
}



.carousel-nystai-home1 .time {
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 3px;
    background-color: #1b6763;
    left: 0;
    top: 0;
}

.carousel-nystai-home1.next .time,
.carousel-nystai-home1.prev .time {
    animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
    from {
        width: 100%
    }

    to {
        width: 0
    }
}




.carousel-nystai-home1.prev .list .item:nth-child(2) {
    z-index: 2;
}

.carousel-nystai-home1.prev .list .item:nth-child(2) .img {
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}

@keyframes outFrame {
    to {
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel-nystai-home1.prev .thumbnail-nystai-slider-home1 .item:nth-child(1) {
    overflow: hidden;
    opacity: 0;
    animation: showthumbnail-nystai-slider-home1 .5s linear 1 forwards;
}

.carousel-nystai-home1.next .arrows button,
.carousel-nystai-home1.prev .arrows button {
    pointer-events: none;
}

.carousel-nystai-home1.prev .list .item:nth-child(2) .content .author,
.carousel-nystai-home1.prev .list .item:nth-child(2) .content .title,
.carousel-nystai-home1.prev .list .item:nth-child(2) .content .topic,
.carousel-nystai-home1.prev .list .item:nth-child(2) .content .des,
.carousel-nystai-home1.prev .list .item:nth-child(2) .content .buttons {
    animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
    to {
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}

@media screen and (max-width: 678px) {
    .carousel-nystai-home1 .list .item .content {
        padding-right: 0;
    }

    .carousel-nystai-home1 .list .item .content .title {
        font-size: 30px;
    }
}













/* card slider */

.section-hover-card-nystai-home .item {
    margin: 10px;
}

.arrow {
    gap: 10px;
}

.arrow button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #b1afaf;
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
}


.rt-profile-card {
    height: 28rem;
    background-color: #FFF;
    background-position: center;
    overflow: hidden;
    position: relative;
    margin: 10px auto;
    cursor: pointer;
    transition: all 2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.rt-profile-card:hover {
    transform: scale(1.01);
}

.rt-profile-card .full-width {
    transition: all linear 0.25s;
    width: 100%;
    height: 100%;
}

.rt-profile-card::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    background: linear-gradient(to top, #1b6763be 15%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
    transition: height linear 0.25s;
    z-index: 1;
}

.rt-profile-card:hover::after {
    height: 100%;
}

.rt-profile-card .rt-profile-body {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    transition: all linear 0.25s;
    z-index: 2;
}

.rt-profile-card .rt-profile-name {
    font-size: 30px;
    line-height: 1;
    color: #FFF;
    text-shadow: 0px 0px 30px rgb(0, 0, 0);
    font-weight: bold;
    position: relative;
    transition: all 400ms ease-out;
    text-transform: uppercase;

}


.rt-profile-card .rt-profile-text {
    display: none;
    opacity: 0;
    font-size: 16px;
    line-height: 1.4;
    color: #FFF;
    text-align: justify;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    height: unset;
    transition: all linear 0.25s;
}

.rt-profile-card:hover .rt-profile-body {
    bottom: 20px;
}

.rt-profile-card:hover .rt-profile-name {
    color: #fff;
}

.rt-profile-card .rt-profile-name::before,
.rt-profile-card .rt-profile-name::after {
    content: "";
    display: inline-block;
    background: #fff;
    border-radius: 20px;
    width: 120px;
    height: 2px;
    margin-right: 0;
    margin-left: 0;
    transition: all 250ms ease-in;
}

.rt-profile-card .rt-profile-name::before {
    position: absolute;
    left: 0;
    top: -7px;
    transform: translateX(0);
}

.rt-profile-card .rt-profile-name::after {

    position: absolute;
    left: 0;
    bottom: -7px;
    transform: translateX(-200px);
}

.rt-profile-card:hover .rt-profile-name::before {
    content: "";
    background: #ffffff;
    transform: translateX(-200px);
}

.rt-profile-card:hover .rt-profile-name::after {

    content: "";
    background: #ffffff;
    transform: translateX(0);
}

.rt-profile-card:hover .rt-profile-text {
    display: block;
    opacity: 1;
    transition: all 400ms ease-out;
    height: initial;
}

@media (max-width: 768px) {
    .rt-profile-card .rt-profile-body {
        bottom: 30px;
    }

    .rt-profile-card .rt-profile-text {
        visibility: visible;
        opacity: 1;
        height: initial;
    }

    .rt-profile-card .rt-profile-name::before {
        display: none;
    }

    .rt-profile-card .rt-profile-name::after {
        position: absolute;
        left: 0;
        bottom: -7px;
        transform: translateX(0);
    }
}